import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Homepage from "./components/Homepage/Homepage";
import Aboutus from "./components/Aboutus/Aboutus";
import Services from "./components/Services/Services";
import Publishers from "./components/Publishers/Publishers";
import Contactus from "./components/Contactus/Contactus";

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Homepage}></Route>
        <Route exact path="/aboutus" component={Aboutus}></Route>
        <Route exact path="/contactus" component={Contactus}></Route>
        <Route exact path="/services" component={Services}></Route>
        <Route exact path="/publishers" component={Publishers}></Route>

      </Switch>
    </Router>
  );
}
