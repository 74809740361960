import React from "react";
import styled from "styled-components";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Modal from "@material-ui/core/Modal";


const Main = styled.main`
  color:#35375E;

  @media (min-width: 300px) and (max-width: 600px) {
    color:#35375E;
    .contact_div {
      width: 80%;
      display: block;
      margin: auto;
      margin-top: 3em;
    }

    .contact_div h4 {
      text-align: center;
      margin-bottom: 2em;
      color: #535461 !important;
      font-weight: bold;
      font-size: 1.2rem;
    }
    .contact_div h5 {
      text-align: left;
      margin-top: 2em;
    }
    .contact_div p {
      text-align: left;
      margin-top: 1.52em;
      font-size: 1rem;
    }
    .lo_div {
      width: 80%;
      margin: auto;
    }
    .lo_div h5 {
      text-align: left;
      margin-bottom: 0px;
      font-size: 0.9rem;
      font-weight: 500;
    }
    .loca_div {
      display: flex;
      margin-bottom: 1.52em;
    }
    .location {
      width: 40px;
    }
    .loca_div p {
      font-size: 0.8rem;
      width: 50%;
      margin-left: 0.5rem;
      text-align: left;
    }
    .email_tele_div {
      text-align: left;
    }
    .email_tele_div p {
      margin-top: 1.5em;
      font-size: 0.9rem;
    }
    .yello {
      display: none;
    }
    .all_input {
      width: 80%;
      margin: auto;
      text-align: left;
    }
    .all_input p {
      margin-top: 1.5em;
      font-size: 0.8rem;
    }
    .fir_input {
      margin-top: 2em;
    }
    .each_input {
      border: 1px solid #575988;
      width: -webkit-fill-available;
      width: -moz-available;
      margin: auto;
      height: 5vh;
      border-radius: 9px;
      padding-inline-start: 10px;
      margin-bottom: 1.5em;
    }
    .sub_div {
      display: block;
    }
    .sub_div input {
      width: -webkit-fill-available;
      width: -moz-available;
      margin: auto;
      border: 1px solid #575988;
      margin-bottom: 1.5em;
      border-radius: 9px;
      height: 5vh;
      padding-inline-start: 10px;
    }
    .sub_div textarea {
      width: -webkit-fill-available;
      width: -moz-available;
      margin: auto;
      border: 1px solid #575988;
      margin-bottom: 1.5em;
      border-radius: 9px;
      height: 12vh;
      padding-inline-start: 10px;
    }
    .butt_div {
      width: 60%;
      margin: auto;
    }
    .butt_div button {
      width: -webkit-fill-available;
      width: -moz-available;
      margin: auto;
      background: #575988;
      color: #fff;
      border: none;
      border-radius: 9px;
      height: 5vh;
      margin-top: 1.4em;
    }
    .design {
      float: right;
    }

    .yello {
      display: none;
    }
    .foo {
      position: relative;
      bottom: -144px;
    }
    .spann {
      font-weight:bold;
    }
  }

  @media (min-width: 600px) and (max-width: 960px) {
    color:#35375E;
    .contact_div {
      width: 80%;
      display: block;
      margin: auto;
      margin-top: 5em;
    }

    .contact_div h4 {
      text-align: center;
      margin-bottom: 2em;
      color: #535461 !important;
    }
    .contact_div h5 {
      text-align: left;
      margin-top: 2em;
    }
    .contact_div p {
      text-align: left;
      margin-top: 1.52em;
      font-size: 1rem;
    }
    .lo_div {
      width: 80%;
      margin: auto;
    }
    .lo_div h5 {
      text-align: left;
      margin-bottom: 0px;
    }
    .loca_div {
      display: flex;
      margin-bottom: 1.52em;
    }
    .location {
      width: 40px;
    }
    .loca_div p {
      font-size: 0.8rem;
      width: 27%;
      margin-left: 0.5rem;
      text-align: left;
    }
    .email_tele_div {
      text-align: left;
    }
    .email_tele_div p {
      margin-top: 1.5em;
    }
    .yello {
      display: none;
    }
    .all_input {
      width: 80%;
      margin: auto;
      text-align: left;
    }
    .all_input p {
      margin-top: 1.5em;
    }
    .fir_input {
      margin-bottom: 1.5em;
      margin-top: 2em;
    }
    .each_input {
      border: 1px solid #575988;
      width: 45%;
      margin-left: 0.5em;
      height: 4vh;
      border-radius: 9px;
      padding-inline-start: 10px;
    }
    .sub_div {
      display: block;
    }
    .sub_div input {
      width: 95%;
      margin: auto;
      border: 1px solid #575988;
      margin-bottom: 1.5em;
      border-radius: 9px;
      height: 4vh;
      padding-inline-start: 10px;
    }
    .sub_div textarea {
      width: 95%;
      margin: auto;
      border: 1px solid #575988;
      margin-bottom: 1.5em;
      border-radius: 9px;
      height: 10vh;
      padding-inline-start: 10px;
    }
    .butt_div {
      width: 60%;
      margin: auto;
    }
    .butt_div button {
      width: -webkit-fill-available;
      width: -moz-available;
      margin: auto;
      background: #575988;
      color: #fff;
      border: none;
      border-radius: 9px;
      height: 4vh;
      margin-top: 1.4em;
    }
    .design {
      float: right;
    }
    .foo {
      position: relative;
      bottom: -146px;
    }
    .spann {
      font-weight:bold;
    }
  }
  @media (min-width: 960px) {
    .main_div {
      display: block;
      margin-top: 4em;
    }
    .pub_div {
      width: 80%;
      margin-top: 3em;
      display: block;
      margin: auto;
    }
    .pub_div h4 {
      text-align: center;
      margin-bottom: 2em;
      color: #535461 !important;
      font-size: 1.92rem;
      font-weight: bold;
    }
    .pub_div p {
      text-align: left;
      width: 90%;
      font-size: 1.1rem;
    }
    .yello {
      float: right;
    }
    .contact_div {
      width: 80%;
      margin: auto;
      margin-top: 5em;
    }
    .contact_div h4 {
      font-size: 1.9rem;
      font-weight: bold;
      color: #535461 !important;
    }
    .contact_div p {
      font-size: 1.1rem;
      text-align: left;
      margin-top: 2em;
    }
    .lo_div {
      width: 80%;
      margin: auto;
    }
    .lo_div h5 {
      text-align: left;
      margin-bottom: 10px;
      font-size:1rem;
    }
    .loca_div {
      display: flex;
      margin-bottom: 1.52em;
    }
    .location {
      width: 40px;
    }
    .loca_div p {
      font-size: 0.8rem;
      width: 27%;
      margin-left: 0.5rem;
      text-align: left;
    }
    .email_tele_div {
      text-align: left;
    }
    .email_tele_div p {
      margin-top: 1.5em;
    }
    .all_input {
      width: 80%;
      margin: auto;
      text-align: left;
    }
    .all_input p {
      margin-top: 1.5em;
    }
    .fir_input {
      margin-bottom: 1.5em;
      margin-top: 2em;
      display: flex;
    }
    .each_input {
      border: 1px solid #575988;
      width: 45%;
      margin-left: 0.5em;
      height: 6vh;
      border-radius: 9px;
      padding-inline-start: 10px;
    }
    .sub_div {
      display: block;
      width: 80%;
    }
    .sub_div input {
      width: 84%;
      margin: auto;
      border: 1px solid #575988;
      margin-bottom: 1.5em;
      border-radius: 9px;
      height: 6vh;
      padding-inline-start: 10px;
    }
    .sub_div textarea {
      width: 84%;
      margin: auto;
      border: 1px solid #575988;
      margin-bottom: 1.5em;
      border-radius: 9px;
      height: 15vh;
      padding-inline-start: 10px;
    }
    .butt_div {
      width: 35%;
    }
    .butt_div button {
      width: -webkit-fill-available;
      width: -moz-available;
      margin: auto;
      background: #575988;
      color: #fff;
      border: none;
      border-radius: 9px;
      height: 7vh;
      margin-top: 1.4em;
    }
    .design {
      display: none;
    }
    .foo {
      position:relative;
      bottom:-200px;
    }
    .spann {
      font-weight:bold;
    }
  }
`;

const Moda = styled.div`




@media (min-width: 300px) and (max-width: 600px) {
    .modal {
      display: block;
      position: relative; /* Stay in place */
      z-index: 1; /* Sit on top */
      left: 0;
      top: 0;
      width: 80%; /* Full width */
      height: 100vh;
      overflow: auto; /* Enable scroll if needed */
      background: rgba(255, 255, 255, 0.1); /* Fallback color */
      margin: auto;
    }

    /* Modal Content */
    .modal-content {
      display: block;
      justify-content: center;
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
      width: -webkit-fill-available;
      width: -moz-available;
      margin-top: 12em;
      height: 27vh;
      background: #fff;
    }
    .modal_subtitle {
      display: block;
      justify-content: center;
      text-align: center;
      font-size: .73rem;
    }
    .div_thank {
      display:flex;
      text-align:center;
      font-size: .73rem;
      justify-content: center;
    }
    .div_thank p {
      text-align:center;
      font-size: .73rem;
    }
    .modal_btns {
      display: block;
      width: 50%;
      margin: auto;
    }
    .modal_btns button {
        width: -webkit-fill-available;
        width: -moz-available;
        margin: auto;
        background: #575988;
        color: #fff;
        border: none;
        border-radius: 9px;
        height: 7vh;
        margin-top: 1.4em;
    }
    .close {
      float: right;
      margin-top: 6em;
      font-size: 1.52em;
    }
    .happy {
      width: 20px;
      height: 20px;
      margin-top: .81em;
    margin-left: 1em;
    }
  }

  @media (min-width: 600px) and (max-width: 960px) {
    .modal {
      display: block;
      position: relative; /* Stay in place */
      z-index: 1; /* Sit on top */
      left: 0;
      top: 0;
      width: 80%; /* Full width */
      height: 100vh;
      overflow: auto; /* Enable scroll if needed */
      margin: auto;
      background: rgba(255, 255, 255, 0.1); /* Fallback color */
    }

    /* Modal Content */
    .modal-content {
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
      width: -webkit-fill-available;
      width: -moz-available;
      margin-top: 8em;
      background: #ffff;
      height: 40vh;
    }
    .modal_subtitle {
      display: block;
      justify-content: center;
      text-align: center;
      font-size: 1.2rem;
    }
    .div_thank {
      display:flex;
      text-align:center;
      font-size: 1.2rem;
      justify-content: center;
    }
    .div_thank p {
      text-align:center;
      font-size: 1.2rem;
    }

    .modal_btns {
      display: block;
      width: 50%;
      margin: auto;
    }
    .modal_btns button {
        width: -webkit-fill-available;
        width: -moz-available;
        margin: auto;
        background: #575988;
        color: #fff;
        border: none;
        border-radius: 9px;
        height: 7vh;
        margin-top: 1.4em;
    }
    .close {
      float: right;
      margin-top: 4em;
      font-size: 1.52em;
    }
    .happy {
      width: 30px;
      height: 30px;
      margin-top: .81em;
    margin-left: 1em;
    }
  }
  @media (min-width: 960px) {
    .modal {
      display: block;
      position: relative;
      z-index: 1;
      left: 0;
      top: 0;
      width: 80%;
      height: 100vh;
      overflow: auto;
      margin: auto;
      background: rgba(255, 255, 255, 0.1); /* Fallback color */
    }

    /* Modal Content */
    .modal-content {
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
      width: -webkit-fill-available;
      width: -moz-available;
      margin-top: 8em;
      background: #ffff;
      height: 31vh;
    }
    .modal_subtitle {
      display: block;
      justify-content: center;
      text-align: center;
      font-size: 1.2rem;
    }
    .div_thank {
      display:flex;
      text-align:center;
      font-size: 1.2rem;
      justify-content: center;
    }
    .div_thank p {
      text-align:center;
      font-size: 1.2rem;
    }

    .modal_btns {
      display: block;
      width: 50%;
      margin: auto;
    }
    .modal_btns button {
        width: -webkit-fill-available;
      width: -moz-available;
      margin: auto;
      background: #575988;
      color: #fff;
      border: none;
      border-radius: 9px;
      height: 7vh;
      margin-top: 1.4em;
    }
    .close {
      float: right;
      margin-top: 4em;
      font-size: 1.52em;
    }
    .happy {
      width: 30px;
      height: 30px;
      margin-top: .81em;
    margin-left: 1em;
    }
  }
`;

export default function Contactus() {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const bodyModal = (
      <Moda>
    <div className="modal">
      <div className="modal-content">
        <div className="modal_subtitle">
          <p>
            {" "}
            We have received your message, and would get back to you as soon as
            possible.{" "}
          </p>
        </div>
        <div className="modal_btns">
          <button
            className="continue_btn"
            onClick={() => {
              handleClose();
            }}
          >
            CLOSE
          </button>
        </div>
      </div>
    </div>
    </Moda>
      );

  return (
    <Main>
      <Header />
      <div className="contact_div">
        <h4>CONTACT US</h4>
        <p>Ready to work with us? Let's talk about how we can help you.</p>
      </div>
      <img src={require("../images/yello.svg")} alt="back" className="yello" />
      <div className="lo_div">
        <h5>Visit us at</h5>
        <div className="loca_div">
          <img
            src={require("../images/location.svg")}
            alt="location"
            className="location"
          />
          <p>19A, Akin Osiyemi street, Allen Avenue, Ikeja, Lagos</p>
        </div>
        <div className="email_tele_div">
          <p>You can also reach out to us through email or telephone.</p>
          <div className="loca_div">
            <img
              src={require("../images/mail.svg")}
              alt="mail"
              className="location"
            />
            <p>sales@chargeadsdigital.com</p>
          </div>
          <div className="loca_div">
            <img
              src={require("../images/phone.svg")}
              alt="phone"
              className="location"
            />
            <p>+2347061573773</p>
          </div>
        </div>
      </div>
      <div className="all_input">
        <p>
          For advertising, publisher consultancy and general inquiries for
          <span className="spann"> CHARGEADS</span>, please use this form:
        </p>
        <div className="fir_input">
          <input placeholder="Full Name" className="each_input" />
          <input placeholder="Email Address" className="each_input" />
        </div>
        <div className="sub_div">
          <input placeholder="Subject" />
          <textarea placeholder="Write your message" />
        </div>
        <div className="butt_div">
          <button onClick={handleClick}>Submit</button>
         
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {bodyModal}
        </Modal>
      </div>
      <img
        src={require("../images/design.svg")}
        alt="design"
        className="design"
      />
      <div className="foo">
        <Footer />
      </div>
    </Main>
  );
}
