import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import gsap, { TweenMax, TimelineMax } from "gsap";
import zIndex from "@material-ui/core/styles/zIndex";

const Main = styled.main`
  @media (min-width: 300px) and (max-width: 600px) {
    .sub_body {
      width: 90%;
      display: flex;
      margin: auto;
    }
    .subtext_div {
      display: flex;
      margin: auto;
      margin-top: 4em;
      width: -moz-available;
    }
    .left {
      width: 3em;
      bottom: -50px;
      position: relative;
      height: 2.5em;
      opacity: 0;
      transform: translateY(-100px);
    }
    .right {
      width: 3em;
      height: 2.5em;
      bottom: -50px;
      position: relative;
      opacity: 0;
      transform: translateY(-100px);
    }
    .sub_text2min {
      font-size: 1rem;
      line-height: 1.2rem;
      width: 100%;
      margin: auto;
      margin-bottom: 2em;
      color:#35375E;
      opacity: 0;
    }
    .sub_text2pmin {
      color:#35375E;
      font-size: 0.9rem;
      opacity: 0;
    }
    .sub_text2 {
      display: none;
    }
    .sub_text2p {
      display: none;
    }
    .chargeads {
      font-size: 2rem !important;
      font-weight: bolder;
      margin-right: 0.3em;
    }
    .button_div {
      width: -webkit-fill-available;
      width: -moz-available;
      background: #fff;
    }
    .button_div button {
      border: 0.51px solid #dddbe2;
      width: 80%;
      margin: auto;
      margin-top: 3em;
      height: 8vh;
      background: #fff;
      border-radius: 7px;
      opacity: 0;
    }
    .back {
      display: none;
    }
  }
  @media (min-width: 600px) and (max-width: 960px) {
    .sub_body {
      width: 90%;
      display: block;
      margin: auto;
    }
    .back {
      display: block;
      margin: auto;
      opacity: 0;
      transform: translateY(-100px);
    }
    .left {
      display: none;
    }
    .right {
      display: none;
    }
    .subtext_div {
      margin-top: 1em;
    }
    .sub_text2 {
      font-size: 2.3rem;
      width: 100%;
      margin: auto;
      text-align: center;
      font-weight: bold;
      color: #35375e;
      margin-top: -9em;
      line-height: 53px;
      opacity: 0;
    }
    .sub_text2p {
      width: 80%;
      margin: auto;
      font-size: 1.2rem;
      color: #35375e;
      margin-top: 1.5em;
      opacity: 0;
    }
    .chargeads {
      font-weight: bolder;
      font-size: 4rem;
      margin-right: 0.3em;
    }
    .button_div {
      width: 36%;
      margin: auto;
      background: #fff;
      margin-top: 4em;
    }
    .button_div button {
      border: 0.51px solid #dddbe2;
      width: 80%;
      margin: auto;
      margin-top: 3em;
      height: 6vh;
      background: #fff;
      border-radius: 7px;
      color: #da0003;
      font-size: 1rem;
      opacity: 0;
    }
    .text_min {
      display: none;
    }
    .back {
    }
  }
  @media (min-width: 960px)  {
    .left {
      display: none;
    }
    .right {
      display: none;
    }
    .back {
      opacity: 0;
      transform: translateY(-100px);
    }
    .subtext_div {
      margin-top: -22em;
    }
    .sub_text2 {
      font-size: 3.4rem;
      width: 80%;
      margin: auto;
      text-align: center;
      font-weight: bold;
      color: #35375e;
      opacity: 0;
    }
    .sub_text2p {
      width: 80%;
      margin: auto;
      font-size: 1.32rem;
      color: #35375e;
      margin-top: 1.5em;
      opacity: 0;
    }
    .chargeads {
      font-weight: bolder;
      font-size: 4rem;
      margin-right: 0.3em;
    }
    .button_div {
      width: 36%;
      margin: auto;
      background: #fff;
      margin-top: 4em;
    }
    .button_div button {
      border: 0.51px solid #dddbe2;
      width: 80%;
      margin: auto;
      margin-top: 3em;
      height: 8vh;
      background: #fff;
      border-radius: 7px;
      color: #da0003;
      font-size: 1rem;
      opacity: 0;
    }
    .text_min {
      display: none;
    }
  }
  @media (min-width: 1500px) {
    .left {
      display: none;
    }
    .right {
      display: none;
    }
    .back {
      opacity: 0;
      transform: translateY(-100px);
    }
    .subtext_div {
      margin-top: -22em;
    }
    .sub_text2 {
      font-size: 3.4rem;
      width: 40%;
      margin: auto;
      text-align: center;
      font-weight: bold;
      color: #35375e;
      opacity: 0;
    }
    .sub_text2p {
      width: 50%;
      margin: auto;
      font-size: 1.32rem;
      color: #35375e;
      margin-top: 1.5em;
      opacity: 0;
    }
    .chargeads {
      font-weight: bolder;
      font-size: 4rem;
      margin-right: 0.3em;
    }
    .button_div {
      width: 36%;
      margin: auto;
      background: #fff;
      margin-top: 4em;
      cursor:pointer;

    }
    .button_div button {
      border: 0.51px solid #dddbe2;
      width: 80%;
      margin: auto;
      margin-top: 3em;
      height: 8vh;
      background: #fff;
      border-radius: 7px;
      color: #da0003;
      font-size: 1rem;
      opacity: 0;
    }
    .text_min {
      display: none;
    }
  }
`;

export default function Firstpart() {
  const history = useHistory();
  const tl = new TimelineMax();
  const back = React.useRef(null);
  const left = React.useRef(null);
  const right = React.useRef(null);
  const abtbutton = React.useRef(null);
  const text1 = React.useRef(null);
  const text2 = React.useRef(null);
  const text1min = React.useRef(null);
  const text2min = React.useRef(null);
  // const back = React.useRef(null)

  React.useEffect(() => {
    tl.to(back.current, 1, { translateY: 0, yoyo: true, delay: 1, opacity: 1 });
    tl.to(left.current, 1, {
      translateY: 0,
      yoyo: true,
      opacity: 1,
      delay: .51, 
      ease: "Back.easeinOut.config( 1.7)",
    });
    tl.to(right.current, 1, {
      translateY: 0,
      yoyo: true,
      opacity: 1,
      delay: .51, 
      ease: "Back.easeinOut.config( 1.7)",
    });
    tl.to(text1.current, {
      translateX: -20,
      translateX: 0,
      translateX: -15,
      ease: "Elastic.easeinOut.config( 1, 0.3)",
      duration: 0.51,
      opacity: 1,
    });
    tl.to(
      text2.current,
      {
        translateX: -20,
        translateX: 0,
        translateX: -15,
        ease: "Elastic.easeinOut.config( 1, 0.3)",
        duration: 0.51,
        opacity: 1,
      }
    );
    tl.to(abtbutton.current, 1, {
      translateX: -5,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });
    tl.to(text1min.current, {
      translateY: -25,
      translateY: 25,
      ease: "Bounce.easeOut",
      duration: 0.51,
      opacity: 1,
    });
    tl.to(
      text2min.current,

      {
        translateY: -25,
        translateY: 25,
        ease: "Bounce.easeOut",
        duration: 0.81,
        opacity: 1,
      }
    );
    tl.to(left.current, 1, {
      translateY: 0,
      yoyo: true,
      opacity: 1,
      ease: "Back.easeinOut.config( 1.7)",
    });
    tl.to(right.current, 1, {
      translateY: 0,
      yoyo: true,
      opacity: 1,
      ease: "Back.easeinOut.config( 1.7)",
    });

  }, [tl]);

  return (
    <Main>
      <div className="sub_body">
        <img
          src={require("../images/background.svg")}
          alt="background"
          className="back"
          ref={back}
        />
        <div className="subtext_div">
          <img
            src={require("../images/backgroundleftball.svg")}
            className="left"
            alt="left"
            ref={left}
          />
          <div>
            <p className="sub_text2" ref={text1}>
              <span className="chargeads">CHARGEADS</span>is a leading digital
              advertising sales agency.
            </p>
            <p className="sub_text2p" ref={text2}>
              CHARGEADS is an advertising sales agency that sells mobile,
              tablet, Desktop display, video(pre, mid and post roll) and in-app
              TV rights, events advertising inventory on behalf of premium
              audience, digital publishers and media owners.{" "}
            </p>
          </div>
          <div className="text_min">
            <p className="sub_text2min" ref={text1min}>
              <span className="chargeads">CHARGEADS</span>is a leading digital
              advertising sales agency.
            </p>
            <p className="sub_text2pmin" ref={text2min}>
              CHARGEADS is an advertising sales agency that sells mobile,
              tablet, Desktop display, video(pre, mid and post roll) and in-app
              TV rights, events advertising inventory on behalf of premium
              audience, digital publishers and media owners.{" "}
            </p>
          </div>
          <img
            src={require("../images/backgroundright.svg")}
            className="right"
            alt="right"
            ref={right}
          />
        </div>
      </div>
      <div className="button_div">
        <button
          onClick={() => {
            history.push("/aboutus");
          }}
          ref={abtbutton}
        >
          More about us
        </button>
      </div>
    </Main>
  );
}
