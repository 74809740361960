import React from "react";
import styled from "styled-components";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import gsap, { TweenMax, TimelineMax } from "gsap";

const Main = styled.main`
  @media (min-width: 300px) and (max-width: 600px) {
    color:#35375E;
    .main_div {
      width: 80%;
      display: block;
      margin: auto;
    }
    .abt_div {
      margin-top: 3em;
    }
    .abt_div h4 {
      text-align: center;
      margin-bottom: 2em;
      color: #535461 !important;
      font-weight: bold;
      font-size: 1.2rem;
    }
    .abt_div h5 {
      text-align: left;
      margin-top: 2em;
    }
    .abt_div p {
      text-align: left;
      margin-top: 1.52em;
      font-size: 0.9rem;
    }
    .abtusmin {
      width: 100%;
      display: flex;
      margin: auto;
      transform:translateX(0);
      opacity:0;

    }
    .howusmax {
      width: 100%;
      display: flex;
      margin: auto;
      transform:translateX(0);
      opacity:0;

    }
    .abtusmax {
      display:none;
    }
    .who {
      opacity:0;
      transform:translateX(0px);
    }
    .spann {
      font-weight:bold;
    }
  }
  @media (min-width: 600px) and (max-width: 960px) {
    color:#35375E;
    .main_div {
      width: 80%;
      display: block;
      margin: auto;
    }
    .abt_div {
      margin-top: 3em;
    }
    .abt_div h4 {
      text-align: center;
      margin-bottom: 2em;
      color: #535461 !important;
      font-weight: bold;
    }
    .abt_div h5 {
      text-align: left;
      margin-top: 2em;
    }
    .abt_div p {
      text-align: left;
      margin-top: 1.52em;
      font-size: 0.7rem;
    }
    .abtus {
      width: 100%;
      display: flex;
      margin: auto;
      opacity:0;

    }
    .who {
      opacity:0;
      transform:translateX(0px);
    }
    .abtusmax {
      margin-top: 5em;
      opacity:0;
      transform:translateX(0);
    }
    .howusmax {
      margin-top: 5em;
      opacity:0;
      transform:translateX(0);
    }
    .spann {
      font-weight:bold;
    }
  }
  @media (min-width: 960px) {
    .abt_div {
      margin-top: 5em;
    }
    .abt_div h4 {
      font-size: 1.9rem;
      font-weight: bold;
    }
    .firstdiv {
      display: flex;
      margin: auto;
      margin-top: 2em;
      justify-content: space-evenly;
    }
    .abtusmax {
      width: 25%;
      margin-top: 5em;
      opacity:0;
      transform:translateX(0);
    }
    .howusmax {
      width: 25%;
      margin-top: 5em;
      opacity:0;
      transform:translateX(0);
    }
    .abtusmin {
      display: none;
    }
    .who {
      width: 50%;
      opacity:0;
      transform:translateX(0px);
    }
    .who h5 {
      font-size: 1.5rem;
      margin-top: 2em;
    }
    .who p {
      font-size: 1rem;
      margin-top: 3em;
    }
    .whomax {
    }
    .spann {
      font-weight:bold;
    }
  }
`;

export default function Aboutus() {
  const tl = new TimelineMax();
  const who = React.useRef(null);
  const whotext = React.useRef(null);
  const whomaxtext = React.useRef(null);
  const howtext = React.useRef(null);
  const whomax = React.useRef(null);
  const how = React.useRef(null);

  React.useEffect(() => {
    tl.to(who.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      delay: .31, 
      opacity: 1,
    });
    tl.to(whomax.current, 1, {
      translateX: 15,
      delay:.1,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });
    tl.to(whotext.current, 1, {
      translateX: -15,
      delay:.1,
      ease: "elastic.out(1, 0.3)",
       opacity: 1,
    });
    tl.to(how.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      opacity:1,
      delay: .1,
    });
  
    tl.to(howtext.current, 1, {
      translateX: -15,
      delay:.1,
      ease: "elastic.out(1, 0.3)",
       opacity: 1,
    });
  
  }, [tl]);

  return (
    <Main>
      <Header />
      <div className="main_div">
        <div className="abt_div">
          <h4>ABOUT US</h4>
          <div className="firstdiv">
            <img
              src={require("../images/abtus.svg")}
              className="abtusmin"
              alt="aboutus"
              ref={who}
            />
            <div className="who" ref={whotext}>
              <h5>Who we are</h5>
              <p>
              <span className="spann">CHARGEADS</span> sells premium advertising inventory on behalf of our
                clients to premium brands without the need for their own sales
                team. This allows them to dedicate more time and resources to
                create great content that their audiences love which in-turn
                generates more advertising revenue for them. Primarily we sell
                advertising inventory across Mobile Desktop and Tablet. We also
                sell space in Apps and pre-roll video. We are here to increase
                your revenue for you. This is on top of what you already bring
                in so anything you have set up already is still 100% yours. Our
                aim is to increase fill rates and quality of advertisers for
                your premium audience.
              </p>
            </div>
            <img
              src={require("../images/abtus.svg")}
              className="abtusmax"
              alt="aboutus"
              ref={whomax}
            />
          </div>
        </div>
        <div className="abt_div">
          <div className="firstdiv">
            <img
              src={require("../images/abtus1.svg")}
              className="howusmax"
              alt="aboutus"
              ref={how}
            />
            <div className="who" ref={howtext}>
              <h5>How we work</h5>
              <p>
              <span className="spann">CHARGEADS</span> sell publishers direct to advertisers. We pitch,
                negotiate and secure premium advertising for our premium
                publishers just like a traditional in-house sales team would do.
                We have extensive contacts in the industry and know where to
                find them if we don’t. We can also arrange, set up and manage
                advertising networks and negotiate the best floor prices and
                once implemented, manage it all on a day to day basis if
                required. We have found that both advertisers and networks want
                to work as efficiently as possible so they use us to place
                advertising for their clients across all our publishers. This
                collective approach enables us to bring in more advertising for
                everyone and gives these advertisers more choice using less
                resources.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Main>
  );
}
