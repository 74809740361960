import React from "react";
import styled from "styled-components";

const FooterS = styled.footer`
  margin-top: 4em;
  @media (min-width: 300px) and (max-width: 600px) {
    .foot_div {
      display: block;
      width: 90%;
      margin: auto;
    }
    .footer_div {
      display: flex;
      width: -moz-available;
      justify-content: center;
    }
    .copy_div {
      display: flex;
      width: -moz-available;
      justify-content: center;
      margin-bottom: 1em;
    }
    .copy_text {
      font-size: 0.7rem;
      margin-bottom: 0px;
      margin-left: 5px;
      margin-top: 1em;

    }

    .footer_div p {
      font-size: 0.8rem;
      margin-top: 1em;

    }
    .insta {
      width: 30px;

      height: 27px;

      margin-left: 0.5em;

      margin-top: auto;

      margin-bottom: auto;
    }
    .twitter {
      margin-left: 0.5em;

      margin-top: 0.5em;
      margin-right: 0.5em;
      width: 25px;
      height: 20px;
    }
    .facebook {
      margin-left: 0.5em;

      margin-top: 0.5em;
      margin-bottom: 0.6rem;
      margin-right: 0.5em;
      width: 25px;
      height: 20px;
    }
    .icons_div {
      display: flex;
    }
    .copy {
      margin-top: 0.5em;
    }
  }
  @media (min-width: 600px) and (max-width: 960px) {
    .footer_div {
      display: flex;
      justify-content: center;
      height: 6vh;
    }
    .footer_div p {
      margin-top: 1em;
    }

    .insta {
      margin: 1em;
      margin-right: 0px;

      margin-top: 18px;

      width: 40px;
    }
    .facebook {
      margin: 1em;
      margin-top: 0px;
      margin-left: 0px;
      width: 30px;
      margin-bottom: 30px;
    }
    .twitter {
      margin: 1em;
      margin-left: 0px;
      margin-top: 3px;
      width: 30px;
      margin-bottom: 30px;  
    }
    .copy_text {
      margin-top: 1em;

      margin-left: 0.5em;
    }
    .copy {
      width: 20px;
    }
    .copy_div {
      display: flex;
      justify-content: center;
    }
  
  }
  @media (min-width: 960px) {
    .footer_div p {
      margin-top: 1em;

      font-size: 1rem;
    }
    .icons_div {
      display: flex;
    }
    .foot_div {
      display: block;
      width: 90%;
      margin: auto;
      margin-top: 15em;
    }
    .footer_div {
      display: flex;
      width: -moz-available;
      width: -webkit-fill-available;
      justify-content: center;
    }
    .insta {
      margin: 1em;
      margin-right: 0px;
      margin-top: 7px;
      cursor:pointer;
      width: 50px;
    }
    .facebook {
      margin: 1em;
      margin-top: 0px;
      margin-left: 0px;
      width: 40px;
      cursor:pointer;

    }
    .twitter {
      margin: 1em;
      margin-left: 0px;
      margin-top: 0px;
      width: 40px;
      cursor:pointer;
    }
    .copy_text {
      margin-top: 1em;

      margin-left: 0.5em;
    }
    .copy {
      width: 25px;
    }
    .copy_div {
      display: flex;
      justify-content: center;
    }
  }
`;

export default function Footer() {
  const facebook = () => {
    var facebookWindow = window.open(
      "https://www.facebook.com/chargeadsdigital",
      "facebook-popup",
      "height=350,width=600,description=Hey Guys checkout my wishlist,caption=Am on wishlist!"
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
    }
  };

  const instagram = () => {
    var instagramWindow = window.open("https://www.instagram.com/chargeads");
    if (instagramWindow.focus) {
      instagramWindow.focus();
    }
  };

  const twitter = () => {
    var twitterWindow = window.open(
      "https://twitter.com/Chargeads1",
      "twitter-popup",
      "height=350,width=600"
    );
    if (twitterWindow.focus) {
      twitterWindow.focus();
    }
    return false;
  };
  return (
    <FooterS>
      <div className="foot_div">
        <div className="footer_div">
          <p>Follow us on social media.</p>
          <div className="icons_div">
            <img
              src={require("../images/instagram2.svg")}
              alt="insta"
              className="insta"
              onClick={()=>{
                instagram();
                }}
            />
            <img
              src={require("../images/twitter.svg")}
              alt="twitter"
              className="twitter"
              onClick={()=>{
                twitter();
                }}
            />
            <img
              src={require("../images/facebook2.svg")}
              alt="facebook"
              className="facebook"
              onClick={()=>{
              facebook();
              }}
            />
          </div>
        </div>
        <div className="copy_div">
          <img
            src={require("../images/copyright1.svg")}
            alt="copyright"
            className="copy"
          />
          <p className="copy_text">CHARGEADS LIMITED 2020</p>
        </div>
      </div>
    </FooterS>
  );
}
