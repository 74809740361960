import React from 'react';
import styled from 'styled-components';
import Header from '../Header/Header';
import Firstpart from './Firstpart';
import Secondpart from "./Secondpart";
import Thirdpart from './Thirdpart';
import FooterS from '../Footer/Footer';
import {useHistory} from 'react-router-dom';

const Main = styled.main`
background:#fff;
.fir_sec {
    margin-top:5em;
}
.sec_thir {
    display:flex;
    justify-content:space-between;
    width: 80%;
    margin: auto;
}
@media (min-width:300px) and (max-width:600px){
    .sec_thir {
        display:block;
    }
    .fir_sec {
        margin-top:0px;
    }
}
@media (min-width:600px) and (max-width:960px){
    .sec_thir {
        display:flex;
        justify-content:space-between;
        width: 80%;
        margin: auto;
    }
    .fir_sec {
        margin-top:0px;
    }
}
@media (min-width: 1500px) {
    .sec_thir {
        display:flex;
        justify-content:space-between;
        width: 50%;
        margin: auto;
    }

}

`;

export default function Homepage() {
const history = useHistory();

    return (
        <Main>
            <Header/>
            <div className="fir_sec">
<Firstpart/>
<div className="sec_thir">
<Secondpart/>
<Thirdpart/>
</div>

            </div>
            <FooterS/>
        </Main>
    )
}
