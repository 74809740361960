import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import gsap, { TweenMax, TimelineMax } from "gsap";

const Main = styled.main`
margin:auto;
margin-top: 3em;
color:#35375E;
width:95%;

@media (min-width: 300px) and (max-width: 600px) {
  .publisher_div {
    display: flex;
    margin: auto;
    opacity:0;
    transform:translateX(-150px),
  }
  }
  .speaker {
    width: 2.5em;
    height: 2em;
    margin-top: .5em;

}
  .pub_text {
    font-size:1rem;
    font-weight:600;
    text-align:left;
    margin-left:.5em;

  }
  .para_div {
    margin: auto;
    display: block;
    opacity:0;
    transform:translateX(150px);
  }
  }
  .para_div p {
    text-align: left;
    font-size: 0.9rem;
  }
  .button_div {
    width: -webkit-fill-available;
    width: -moz-available;
  }
  .button_div button {
    border: none;
    width: 80%;
    margin: auto;
    margin-top: 3em;
    height: 8vh;
    color: #fff;
    border-radius: 7px;
    background: #da0003;
    opacity: 0;
    cursor:pointer;

  }
  .spann {
    font-weight:bold;
  }
}
@media (min-width:600px) and (max-width:960px){
  .publisher_div {
    display:flex;
    opacity:0;
    transform:translateX(0px);  
  }
  .para_div {
    margin-top: 1em;
height:25vh;
text-align: left;
opacity:0;
transform:translateX(150px);
  }
  .pub_text {
    margin-top: 2em;
    font-size: 1.2rem;
margin-left: .51em;
text-align: left;

  }
  .publisher_div img {
    width: 60px;
    height:auto;
  }
  .button_div {
    width:-webkit-fill-available;
    width:-moz-available;
        margin:auto;
  }
  .button_div button {
    border: none;
    margin: auto;
    width:-webkit-fill-available;
    width:-moz-available;
    margin-top: 3em;
    height: 6vh;
    color: #fff;
    border-radius: 7px;
    background: #da0003;
    opacity: 0;
    cursor:pointer;

  }
  .spann {
    font-weight:bold;
  }
}
@media (min-width:960px){
  .publisher_div {
    display:flex;
    opacity:0;
    transform:translateX(-150px),
  }
  .para_div {
    margin-top: 1em;
height:25vh;
text-align: left;
opacity:0;
transform:translateX(150px);
  }
  .pub_text {
    margin-top: 2em;
    font-size: 1.2rem;
margin-left: .51em;
text-align: left;

  }
  .publisher_div img {
    width: 60px;
    height:auto;

  }
  .button_div {
    width:-webkit-fill-available;
    width:-moz-available;
        margin:auto;
  }
  .button_div button {
    border: none;
    margin: auto;
    width:-webkit-fill-available;
    width:-moz-available;
    margin-top: 3em;
    height: 6vh;
    color: #fff;
    border-radius: 7px;
    background: #da0003;
    opacity: 0;
    cursor:pointer;

  }
  .spann {
    font-weight:bold;
  }
}
@media (min-width: 1500px) {

  .publisher_div img {
    width: 60px;
  }
  .button_div {
    width:80%;
        margin:auto;
        cursor:pointer;

  }
  .spann {
    font-weight:bold;
  }
}
`;

export default function Thirdpart() {
  const tl = new TimelineMax();
  const speaker = React.useRef(null);
  const text = React.useRef(null);
  const button = React.useRef(null);

  React.useEffect(() => {
    tl.to(speaker.current, 0.5, {
      translateX: 0,
      ease: "Bounce.easeOut",
      delay: 1,
      opacity: 1,
    });
    tl.to(text.current, 0.5, {
      translateX: 0,
      ease: "Bounce.easeOut",
      delay: 2,
      opacity: 1,
    });
    tl.to(button.current, 1, {
      translateX: -5,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
      delay: .81,
    });
  }, [tl]);
  const history = useHistory();
  return (
    <Main>
      <div className="publisher_div" ref={speaker}>
        <img
          src={require("../images/Groupspeaker.svg")}
          alt="speaker"
          className="speaker"
        />
        <p className="pub_text">
          ADVERTISERS, SAY HELLO TO BIG, BRAND-SAFE RESULTS
        </p>
      </div>
      <div className="para_div" ref={text}>
        <p>
          Our publishers will deliver to your requirements and we ensure all
          campaigns are completed on time and on budget giving you a reliable
          and trusted agency that cares about results. Our network of audience
          first publishers, brands can reach millions of potential and future
          customers from a global and highly varied demographic who visit and
          use our publisher partners websites and apps every single day.
        </p>
      </div>
      <div className="button_div">
        <button
          ref={button}
          onClick={(e) => {
            history.push("/publishers");
          }}
        >
          More info for clients
        </button>
      </div>
    </Main>
  );
}
