import React from "react";
import styled from "styled-components";
import { FiMenu } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { useHistory } from "react-router-dom";

const Section = styled.section`

  @media(min-width:300px) and (max-width:600px){
    .nav {
      width: 95%;
margin: auto;
    }
    .navbar {
        display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0;
        padding-top: 0px;
    padding-top: 0.51em;
    height: 12vh;
    margin: auto;
       }
       .navbar-nav {
         display: flex;
         flex-direction: row !important;
         margin-left: auto;
         margin-right: auto;
         width:100%;
     
       }
       .logodiv {
        height: auto;
      }
      .newlogo {
        cursor:pointer;
         height: auto;
        width: 11em;
        margin-top:-3em;
      }
    
    .menudiv {
        display: contents;
              margin-top: -.53em;
      margin-left: -1.2em;
    }
  .menudiv button {
    background: transparent;
    border: none;
    cursor:pointer;
    margin-top: -3em;
  }
  .menu {
    width: 2.5em;
    height: 2em;
    stroke: #35375E;
  }
  .nav-pills{
      display:none;
  }
  .allItems {
    display:grid;
  }
  .allItems span {
    margin: 1.72em;
  }
  .allItems span a {
    text-decoration:none;
    color:#35375E;

  }
  .menu3 {
    position: relative;
    z-index: 10;
    background: white;
    width: -moz-available;
    width: -webkit-fill-available;
  }
  .closediv{
    margin-top: 2em;
  }
  }
 @media (min-width:600px) and (max-width:960px){
  .nav {
    width: 95%;
margin: auto;
height: 6vh;
  }
  .navbar {
      display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;
      padding-top: 0px;
  padding-top: 0.51em;
  margin: auto;
     }
     .navbar-nav {
       display: flex;
       flex-direction: row !important;
       margin-left: auto;
       margin-right: auto;
       width:100%;
   
     }
     .logodiv {
      width: 15em;
    }
    .newlogo {
    cursor:pointer;
      margin-top: -3em;
      height: auto;
      width: 11em;
    }
  
  .menudiv {
      display: grid;
            margin-top: -.53em;
    margin-left: -1.2em;
  }
.menudiv button {
  background: transparent;
  border: none;
  cursor:pointer;
  margin-top: -3em;
}
.menu {
  width: 2.5em;
  height: 2em;
  stroke: #35375E;
}
.nav-pills{
    display:none;
}
.allItems {
  display:grid;
}
.allItems span {
  margin: 1.72em;
}
.allItems span a {
  text-decoration:none;
  color:#35375E;

}
.menu3 {
  position: relative;
  z-index: 10;
  background: white;
  width: -moz-available;
  width: max-content;
  width: -webkit-fill-available;
  margin-top:-3em;
}
.closediv{
  margin-top: 2em;
}

   .nav-pills {
     display:none;
   }

 }
 @media (min-width:960px){
  .nav {
height: 6vh;

  }
  .navbar {
      display: flex;
  width: 90%;
  padding: 0;
      padding-top: 0px;
  padding-top: 0.51em;
  margin: auto;
  justify-content: space-between;
     }
     .navbar-nav {
       display: flex;
       flex-direction: row !important;
       margin-left: auto;
       margin-right: auto;
       width:100%;
   
     }
     .logodiv {
      width: 15em;
      cursor:pointer;

    }
    .newlogo {
      cursor:pointer;
      margin-top: -3em;
      height: auto;
      width: 11em;
    }
   

  .nav-pills {
    display:flex;
    margin-top:1em;

  }
  .menudiv {
    display:none;
  }

  ul {
    list-style:none;

  }
  
 
 }
`;

const Link = styled.a`
  color: #35375e;
  text-decoration: none;
  transition: color 0.55s;
  margin: 1em;
  font-size: 1em;
  cursor:pointer;
  transition-duration: 9s;
  transition-delay: 3s;
  transition-timing-function: ease-in-out;


`;
const Span = styled.span`
  ${Link}: hover & {
    color: #da0003;
    text-decoration: none;
    font-size: 1.02em;
    transition-timing-function: ease-in-out;
    margin-top:.3em;

  }
  ${Link}: focus & {
    color: #da0003;
    text-decoration: none;
    font-size: 1.02em;

  }

  .newlogo {
    cursor:pointer;

  }
`;

export default function Header() {
  const [showList, setShowList] = React.useState(false);
  const history = useHistory();
  return (
    <Section>
      <nav className="nav">
        <ul className="navbar nav">
          <div className="logodiv">
            <a href={require("../images/chargeads.pdf")} target="_blank"
 rel="noopener noreferrer" 
 >
            <img
              src={require("../images/chargeads.png")}
              alt="logo"
              className="newlogo"

              onClick={() => {
                // window.open("../images/GeneralProposal.pdf")
              }}
            />
            </a>
          
          </div>
          <div className="menudiv">
            <button
              onClick={() => {
                setShowList(!showList);
              }}
            >
              <FiMenu className="menu" />
            </button>
            {showList && (
              <div className="menu3">
                <div className="overlay_effect">
                  <div className="closediv">
                    <button
                      className="btn_close"
                      onClick={() => {
                        setShowList(!showList);
                      }}
                    >
                      <IoMdClose className="close" />
                    </button>
                  </div>
                  <div className={"allItems"}>
                    <Link
                      onClick={() => {
                        history.push("/");
                      }}
                      className={"all_us"}
                    >
                      <Span>HOME</Span>
                    </Link>
                    <Link
                      onClick={() => {
                        history.push("/aboutus");
                      }}
                      className={"all_us"}
                    >
                      <Span>ABOUT US</Span>
                    </Link>
                    <Link
                      onClick={() => {
                        history.push("/services");
                      }}
                      className={"all_us"}
                    >
                      <Span>OUR SERVICES</Span>
                    </Link>
                    <Link
                      onClick={() => {
                        history.push("/publishers");
                      }}
                      className={"all_us"}
                    >
                      <Span>OUR PUBLISHERS</Span>{" "}
                    </Link>
                    <Link
                      onClick={() => {
                        history.push("/contactus");
                      }}
                      className={"all_us"}
                    >
                      <Span>CONTACT US</Span>{" "}
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="nav nav-pills">
          <li className="header_links">
              <Link
                onClick={() => {
                  history.push("/");
                }}
              >
                <Span>HOME</Span>
              </Link>
            </li>
            <li className="header_links">
              <Link
                onClick={() => {
                  history.push("/aboutus");
                }}
              >
                <Span>ABOUT US</Span>
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  history.push("/services");
                }}
              >
                <Span>OUR SERVICES</Span>
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  history.push("/publishers");
                }}
              >
                <Span>OUR PUBLISHERS</Span>
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  history.push("/contactus");
                }}
              >
                <Span>CONTACT US</Span>
              </Link>
            </li>
          </div>
        </ul>
      </nav>
    </Section>
  );
}
