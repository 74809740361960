import React from "react";
import styled from "styled-components";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import gsap, { TweenMax, TimelineMax } from "gsap";
import {useHistory} from 'react-router-dom'

const Main = styled.main`
color:#35375E;
@media (min-width: 300px) and (max-width: 600px) {
    .main_div {
      width: 90%;
      display: block;
      margin: auto;
    }
    .pub_div {
      margin-top: 3em;
    }
    .pub_divh4 {
      text-align: center;
      margin-bottom: 2em;
      color: #535461 !important;
      font-weight: bold;
      font-size: 1.2rem;

    }
    .pub_divp {
      text-align: left;
      font-size: 0.75rem;
      margin-top: 1em;
      width: 90%;
      opacity: 0;

    }
    .divp {
      margin-top: 1em;
      opacity: 0;

    }
    .digi_plat_divh6 {
      text-align: center;
      margin-bottom: 2em;
      margin-top: 2em;
      color: #535461 !important;
      font-size: 1rem;
      opacity: 0;
    }
    .digi_plat_divh62 {
      text-align: center;
      margin-bottom: 2em;
      margin-top: 2em;
      color: #535461 !important;
      font-size: 1rem;
      opacity: 0;
    }
    .digi_plat_divh63 {
      text-align: center;
      margin-bottom: 2em;
      margin-top: 2em;
      color: #535461 !important;
      font-size: 1rem;
      opacity: 0;
    }
    .firsticons {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: auto auto;
      justify-content: center;
      align-content: center;
      width: 90%;
      grid-gap: 3em;
      margin: auto;
      margin-top: 3em;
      opacity: 0;
    }
    .secondicons {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: auto auto;
      justify-content: center;
      align-content: center;
      width: 100%;
      grid-gap: 3em;
      margin-top: 3em;
      opacity: 0;
    }
    .thirdicons {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: auto auto;
      justify-content: center;
      align-content: center;
      width: 100%;
      grid-gap: 3em;
      margin-top: 3em;
      opacity: 0;
    }
    .vskit {
      width: 150px;
      height: 100px;
    }
    .red {
      display: none;
    }
    .spann {
      font-weight: bold;
    }
    .button_div {
      width: -webkit-fill-available;
      width: -moz-available;
      margin: auto;
    }
    .button_div button {
      border: none;
      margin: auto;
      width: 50%;
      margin-top: 3em;
      height: 6vh;
      color: #fff;
      border-radius: 7px;
      background: #da0003;
      opacity: 0;
      cursor: pointer;
    }
    .pup {
      text-align: left;
      width: 90%;
      font-size: 1.1rem;
      opacity: 0;
      margin-top: 1em;
    }
    .pub_divp {
      text-align: left;
      width: 90%;
      font-size: 1.1rem;
      opacity: 0;
      margin-top: 1em;
    }
    .divp {
      text-align: left;
      width: 90%;
      font-size: 1rem;
      opacity: 0;
      margin-top: 1em;
    }
  }
  @media (min-width: 600px) and (max-width: 960px) {
    .main_div {
      width: 80%;
      display: block;
      margin: auto;
    }
    .pub_div {
      margin-top: 3em;
    }
    .pub_div h4 {
      text-align: center;
      margin-bottom: 2em;
      color: #535461 !important;

    }
    .pub_div p {
      text-align: left;
      font-size: 0.75rem;
      margin-top: 1em;
      opacity: 0;

    }
    .divp {
      margin: auto;
      text-align: left;
      width: 90%;
      font-size: 1rem;
      opacity: 0;
      margin-top: 1em;
  
    }
    .digi_plat_divh6 {
      text-align: center;
      margin-bottom: 1em;
      margin-top: 1em;
      color: #535461 !important;
      font-size: 1.5rem;
      opacity: 0;
    }
    .digi_plat_divh62 {
      text-align: center;
      margin-bottom: 1em;
      margin-top: 1em;
      color: #535461 !important;
      font-size: 1.5rem;
      opacity: 0;
    }
    .digi_plat_divh63 {
      text-align: center;
      margin-bottom: 1em;
      margin-top: 1em;
      color: #535461 !important;
      font-size: 1.5rem;
      opacity: 0;
    }
    .firsticons {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: auto auto auto auto;
      justify-content: center;
      align-content: center;
      width: 100%;
      grid-gap: 3em;
      margin: auto;
      margin-top: 1em;
      opacity: 0;
    }
    .secondicons {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: auto auto;
      justify-content: center;
      align-content: center;
      width: 100%;
      grid-gap: 3em;
      margin-top: 1em;
      opacity: 0;
    }
    .thirdicons {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: auto auto auto auto;
      justify-content: center;
      align-content: center;
      width: 100%;
      grid-gap: 3em;
      margin-top: 1em;
      opacity: 0;
    }
    .vskit {
      width: 130px;
      height: 100px;
    }
    .red {
      display: none;
    }
    .spann {
      font-weight: bold;
    }
    .button_div {
      width: -webkit-fill-available;
      width: -moz-available;
      margin: auto;
    }
    .button_div button {
      border: none;
      margin: auto;
      width: 50%;
      margin-top: 3em;
      height: 6vh;
      color: #fff;
      border-radius: 7px;
      background: #da0003;
      opacity: 0;
      cursor: pointer;
    }
    .pup {
      text-align: left;
      width: 90%;
      font-size: 1.1rem;
      opacity: 0;
      margin-top: 1em;
    }
    .pub_divp {
      text-align: left;
      width: 90%;
      font-size: 1.1rem;
      opacity: 0;
      margin-top: 1em;
    }
    .divp {
      text-align: left;
      width: 90%;
      font-size: 1rem;
      opacity: 0;
      margin-top: 1em;
    }
  }
  @media (min-width: 960px) {
    .main_div {
      display: block;
      margin-top: 4em;
    }
    .pub_div {
      width: 80%;
      margin-top: 3em;
      display: block;
      margin: auto;
    }
    .sec_div {
      width: 70%;
    }
    .pub_divh4 {
      text-align: center;
      margin-bottom: 2em;
      margin-top: 4em;

      color: #535461 !important;
      font-size: 1.92rem;
      font-weight: bold;
    }
    .pup {
      text-align: left;
      width: 90%;
      font-size: 1.1rem;
      opacity: 0;
      margin-top: 1em;
    }
    .pub_divp {
      text-align: left;
      width: 90%;
      font-size: 1.1rem;
      opacity: 0;
      margin-top: 1em;
    }
    .divp {
      margin: auto;
      text-align: left;
      width: 90%;
      font-size: 1rem;
      opacity: 0;
      margin-top: 1em;
  
    }
    .digi_plat_div {
      margin: auto;
    }
    .digi_plat_divh6 {
      margin: auto;
      text-align: center;
      margin-bottom: 1em;
      margin-top: 1em;
      color: #535461 !important;
      font-size: 1.82em;
      opacity: 0;
      font-weight: 600;
    }
    .digi_plat_divh62 {
      margin: auto;
      text-align: center;
      margin-bottom: 1em;
      margin-top: 1em;
      color: #535461 !important;
      font-size: 1.82em;
      opacity: 0;
    }
    .digi_plat_divh63 {
      margin: auto;
      text-align: center;
      margin-bottom: 1em;
      margin-top: 1em;
      color: #535461 !important;
      font-size: 1.82em;
      opacity: 0;
    }

    .firsticons {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: auto auto auto auto auto;
      justify-content: center;
      align-content: left;
      grid-gap: 3em;
      margin: auto;
      margin-top: 1em;
      opacity: 0;
    }

    .secondicons {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: auto auto;
      justify-content: center;
      align-content: left;
      grid-gap: 3em;
      margin-top: 1em;
      opacity: 0;
    }

    .thirdicons {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: auto auto auto auto auto;
      justify-content: center;
      align-content: left;
      grid-gap: 3em;
      margin-top: 1em;
      opacity: 0;
    }
    .vskit {
      width: 150px;
      height: 150px;
    }
    .red {
      float: right;
    }
    .allicons {
      width: 80%;
      margin: auto;
    }
    .spann {
      font-weight: bold;
    }
    .button_div {
      width: -webkit-fill-available;
      width: -moz-available;
      margin: auto;
    }
    .button_div button {
      border: none;
      margin: auto;
      width: 50%;
      margin-top: 3em;
      height: 6vh;
      color: #fff;
      border-radius: 7px;
      background: #da0003;
      opacity: 0;
      cursor: pointer;
    }
  }
`;

export default function Publishers() {
  const history = useHistory();
  const tl = new TimelineMax();
  const pubp = React.useRef(null);
  const pbp = React.useRef(null);
  const pup = React.useRef(null);
  const pup3 = React.useRef(null);
  const pup2 = React.useRef(null);
  const pup1 = React.useRef(null);
  const pubp1 = React.useRef(null);
  const pubp2 = React.useRef(null);
  const pubp3 = React.useRef(null);
  const pubp4 = React.useRef(null);
  const pup4 = React.useRef(null);
  const pup5 = React.useRef(null);
  const pup6 = React.useRef(null);
  const pbp1 = React.useRef(null);
  const pbp2 = React.useRef(null);
  const pbp3 = React.useRef(null);
  const pbp4 = React.useRef(null);
  const red = React.useRef(null);
  const h61 = React.useRef(null);
  const firsticons = React.useRef(null);
  const h62 = React.useRef(null);
  const secondicons = React.useRef(null);
  const h63 = React.useRef(null);
  const thirdicons = React.useRef(null);
  const button = React.useRef(null);

  React.useEffect(() => {
    tl.to(pup.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });
    tl.to(pubp.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });
    tl.to(pbp.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });
    tl.to(pup1.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });

    tl.to(pubp1.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });
    tl.to(pbp1.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });
    tl.to(pup2.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });
    tl.to(pubp2.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      delay: 0.1,
      opacity: 1,
    });
    tl.to(pbp2.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });
    tl.to(pup3.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });

    tl.to(pubp3.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      delay: 0.1,
      opacity: 1,
    });
    tl.to(pbp3.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });
    tl.to(pup4.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });
    tl.to(pubp4.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });
    tl.to(pbp4.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });
    tl.to(pup5.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });

    tl.to(h61.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });
    tl.to(red.current, 1, {
      ease: "Bounce.easeOut",
      opacity: 1,
    });
    tl.to(firsticons.current, 1, {
      translateX: -15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });
    tl.to(h62.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });
    tl.to(secondicons.current, 1, {
      translateX: -15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });
    tl.to(h63.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });
    tl.to(thirdicons.current, 1, {
      translateX: -15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });
    tl.to(button.current, 1, {
      translateX: -5,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
      delay: 0.81,
    });
  }, [tl]);
  return (
    <Main>
      <Header />
      <div className="main_div">
        <div className="pub_div">
          <h4 className="pub_divh4">OUR PUBLISHERS</h4>
          <p className="pub_divp" ref={pup}>
            Connect with your prospects and monetize your events with booth
            purchases sponsorships, TV rights properties and collateral. Focus
            on your conference while <span className="spann">CHARGEADS </span>
            focuses on your revenue.
          </p>
          <div className="pub_divp" ref={pubp}>
            <span className="spann" ref={pbp}>
              Join our network of publishers.
            </span>
            <br />
            <p className="divp" ref={pup1}>
              <span className="spann">CHARGEADS </span> has been helping
              publishers for years to monetize their inventory and now we can
              offer even more with the <span className="spann">CHARGEADS </span>{" "}
              publisher network. Joining the{" "}
              <span className="spann">CHARGEADS </span> publisher network brings
              substantial benefits to to publishers, bloggers and App developers
              who are looking to maximize their digital advertising revenue
              generated in all geo’s that they have audience in. We’re here to
              join everything together and manage it all for you on a daily
              basis, freeing you up to do what you love best, publishing.
            </p>
          </div>
          <div className="pub_divp" ref={pubp1}>
            <span className="spann" ref={pbp1}>
              Access to Advertising Networks and so much More
            </span>
            <br />
            <p className="divp" ref={pup2}>
              We have extensive contacts and relationships with some of the
              worlds biggest and best ad networks, private marketplaces and
              native content providers. Because of this we can get your site
              connected and generating revenue pretty much immediately and your
              site will look great as it will have relevant and premium brand
              adverts. Now with our header bidding partners we can squeeze even
              more out of every users visit as well.
            </p>
          </div>
          <div className="pub_divp" ref={pubp2}>
            <span className="spann" ref={pbp2}>
              {" "}
              Additional revenue without the hassle.
            </span>
            <br />
            <p className="divp" ref={pup3}>
              Our service is not here to take over what you already have, its
              here to compliment and increase the potential that your site truly
              has. The only thing joining the{" "}
              <span className="spann">CHARGEADS </span> publisher network will
              do is increase the revenue that your site generates and maximize
              the revenue on a global basis. We deal with everything for you. We
              manage the day to day relationships, negotiate floor prices for
              inventory, compile the reporting across all the networks we
              connect you with and collect all the digital advertising revenue
              for you. This is as well as pitching your site out for direct
              campaigns and all the usual things associated with having your own
              sales team, leaving you free to continue creating compelling and
              engaging content for your growing audience which will give us even
              more inventory to sell on your behalf.
            </p>
          </div>
          <div className="pub_divp" ref={pubp3}>
            <span className="spann" ref={pbp3}>
              {" "}
              Access (Not denied).
            </span>
            <p className="divp" ref={pup4}>
              One of the other major benefits is that some of the networks we
              are connected to will have very high traffic requirements for
              entry. If you are with us this will not apply to you as we
              represent publisher’s who collectively serve billions of adverts
              per year across mobile and desktop. This enables us to get you up
              and running when on your own you won’t qualify if you don’t have
              high enough traffic volumes. More access to branded campaigns. We
              will also package you up alongside sites we have that may have
              similar audiences and put you across to advertising agencies
              collectively. This means that the potential for being put on
              schedules increases greatly. Agencies and marketing managers are
              busy people, having one point of contact and one invoice saves
              them time, gets them more reach for less hassle than dealing with
              individual account managers across individual sites.
            </p>
          </div>
        </div>

        <div className="allicons">
          <p className="divp" ref={pup5}>
            Here is a selection of some of the publishers{" "}
            <span className="spann"> CHARGEADS </span> works with and represents
            directly for sponsorship opportunities.
          </p>
          <div className="digi_plat_div">
            <h4 className="digi_plat_divh6" ref={h61}>
              Digital Platforms
            </h4>
            <div className="firsticons" ref={firsticons}>
              <a href={require("../images/Vskit.pdf")} target="_blank" rel="noopener noreferrer" >
                <img
                  src={require("../images/vskit.svg")}
                  alt="vskit"
                  className="vskit"
                />
              </a>
              <a
                href={require("../images/boomplay.pdf")}
                target="_blank"
                rel="noopener noreferrer" 
              >
              <img
                src={require("../images/boomplay.svg")}
                alt="boomplay"
                className="vskit"
              />
              </a>
              <a
                href={require("../images/GeneralProposal.pdf")}
                target="_blank"
                rel="noopener noreferrer" 
              >
                <img
                  src={require("../images/guardian.svg")}
                  alt="guardian"
                  className="vskit"
                />
              </a>
              <img
                src={require("../images/newswatch.svg")}
                alt="newswatch"
                className="vskit"
              />
               
              <a href={require("../images/audiomack.pdf")} target="_blank" rel="noopener noreferrer" >
                <img
                  src={require("../images/audiomack.svg")}
                  alt="audiomack"
                  className="vskit"
                />
              </a>
              <img
                src={require("../images/opera.jpg")}
                alt="OperaMini"
                className="vskit"
              />
            </div>
          </div>
          <div className="digi_plat_div">
            <h4 className="digi_plat_divh62" ref={h62}>
              Print Platforms
            </h4>
            <div className="secondicons" ref={secondicons}>
              <a href="https://media.guardian.ng">
              <img
                src={require("../images/guardian.svg")}
                alt="guardian"
                className="vskit"
              />
              </a>
              <img
                src={require("../images/Tribune.svg")}
                alt="tribune"
                className="vskit"
              />
            </div>
          </div>
          <div className="digi_plat_div">
            <h4 className="digi_plat_divh63" ref={h63}>
              TV/Events and Properties
            </h4>
            <div className="thirdicons" ref={thirdicons}>
              <img
                src={require("../images/laliga.svg")}
                alt="laliga"
                className="vskit"
              />
              <img
                src={require("../images/lagos.svg")}
                alt="lagosfestival"
                className="vskit"
              />
              <img
                src={require("../images/uefaeuro2021.svg")}
                alt="uefa"
                className="vskit"
              />
              <img
                src={require("../images/thevoice.svg")}
                alt="thevoice"
                className="vskit"
              />
               <img
                  src={require("../images/pop1.jpg")}
                  alt="popcampus"
                  className="vskit"
                />
            </div>
          </div>
        </div>
      </div>
      <div className="button_div">
        <button ref={button} onClick={()=>{
          history.push("/contactus")
        }}>Publishers, Sign Up</button>
      </div>
      <Footer />
    </Main>
  );
}
