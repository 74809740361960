import React from "react";
import styled from "styled-components";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import gsap, { TweenMax, TimelineMax } from "gsap";

const Main = styled.main`
  @media (min-width: 300px) and (max-width: 600px) {
    .main_services {
      width: 100%;
      display: block;
      margin: auto;
    }
    .main_services h4 {
      margin-top: 1.52em;
      text-align: center;
      font-size: 1.2rem;
      color: #535461;
      font-weight: bold;
    }
    .main_services h3 {
      margin-top: 1.5em;
      margin-bottom: 2em;
      text-align: center;
      font-weight: bolder;
      font-size: 1.34rem;
      color: #535461;
    }
    .green_div {
      display: flex;
      width: 95%;
      margin: auto;
      margin-top: -2em;
    }
    green_div img {
      width: 15px;
      position: relative;
      z-index: -1;
    }
    .greendivp {
      display: block;
      font-size: 0.9rem;
      text-align: left;
      margin: auto;
      color:#35375E;
      opacity: 0;
    }
    .ull {
      width: 90%;
      display: block;
      font-size: 0.8rem;
      text-align: left;
      margin: auto;
      margin-top: 1em;
      margin-bottom: 1em;
      font-weight: bold;
      color:#35375E;
      opacity: 0;
    }
    .lli {
      color:#35375E;
      margin-top: 0.3em;
    }
    .sales_div {
      display: flex;
      width: 95%;
      margin: auto;
    }
    .salesdivp {
      width: 95%;
      display: block;
      font-size: 0.9rem;
      text-align: left;
      margin: auto;
      margin-top: 2em;
      color:#35375E;
      opacity: 0;
    }
    .sales_div img {
      margin-top: -3em;
      margin-left: -1em;
      position: relative;
    }
    .adsales_banner {
      margin-top: 3em;
    }
    .adsales_bannerh4 {
      font-size: 1.1rem;
      text-align: center;
      color: #535461;
      opacity: 0;
    }
    .one {
      display: block;
      margin-top: 3em;
      color:#35375E;
      opacity: 0;
      transform: translateX(0);
    }
    .one_div {
      width: 80%;
      display: grid;
      margin: auto;
      margin-top: 1em;
    }
    .one_icon {
      position: relative;
      float: left;
      margin-left: 1em;
      position: relative;
      margin-top: -1.52em;
      height: 11vh;
    }
    .one_text {
      width: 80%;
      position: absolute;
      background: #fff;
      font-size: 0.8rem;
      z-index: 10;
      text-align: left;
      padding-top: 0.5em;
      height: 4vh;
    }
    .one_mini_text {
      text-align: left;
      margin: auto;
      margin-top: 1em;
      color:#35375E;
      width: 95%;
    }
    .one_mini_textp {
      font-size: 0.9rem;
      opacity: 0;
      transform: translateX(0);
    }
    .polygon {
      position: absolute;
      z-index: -1;
      margin-left: 1em;
      opacity: 0;
    }
    .two {
      display: block;
      margin-top: 3em;
      color:#35375E;
      opacity: 0;
      transform: translateX(0);
    }
    .two_div {
      width: 80%;
      display: grid;
      margin: auto;
    }
    .two_icon {
      position: relative;
      float: right;
      margin-left: 1em;
      position: relative;
      margin-top: -1.52em;
      height: 11vh;
    }
    .two_text {
      width: 100%;
      position: absolute;
      background: #fff;
      font-size: 0.8rem;
      z-index: 10;
      text-align: right;
      padding-top: 0.5em;
      height: 4vh;
    }
    .two_mini_text {
      text-align: center;
      margin: auto;
      margin-top: 1em;
      color:#35375E;
      width: 95%;
    }
    .two_mini_textp {
      font-size: 0.9rem;
      text-align: left;
      opacity: 0;
      transform: translateX(0);
    }
    .red_div {
      text-align: right;
      width: 95%;
    }
    .rectangle {
      text-align: center;
      opacity: 0;
      position:absolute;
    }
    .red {
      text-align: left !important;
      margin-top: 3em;
      opacity: 0;
    }
    .polygon {
      position: absolute;
      z-index: -1;
      margin-left: 1em;
      opacity: 0;
    }
    .ellipse {
      float: left;
      margin-left: 2em;
      margin-top: 3em;
      opacity: 0;
    }
    .three {
      display: block;
      margin-top: 3em;
      color:#35375E;
      opacity: 0;
      transform: translateX(0);
    }
    .three_div {
      width: 80%;
      display: grid;
      margin: auto;
      margin-top: 2em;
    }
    .three_icon {
      position: relative;
      float: left;
      margin-left: 1em;
      position: relative;
      margin-top: -1.52em;
      height: 11vh;
    }
    .three_text {
      width: 80%;
      position: absolute;
      background: #fff;
      font-size: 0.8rem;
      z-index: 10;
      text-align: left;
      padding-top: 0.5em;
      height: 4vh;
    }
    .three_mini_text {
      text-align: left;
      margin: auto;
      margin-top: 1em;
      display: flex;
      justify-content: flex-end;
      width: 90%;
      color:#35375E;
    }
    .three_mini_textp {
      font-size: 0.9rem;
      opacity: 0;
      transform: translateX(0);
    }
    .poygon {
      width: 2%;
      position: absolute;
      z-index: -5;
      opacity: 0;
    }
    .tw_divmax {
      display: none;
    }
    .spann {
      font-weight: bold;
    }
  }

  @media (min-width: 600px) and (max-width: 960px) {
    .main_services {
      width: 100%;
      display: block;
      margin: auto;
    }
    .main_services h4 {
      margin-top: 3em;
      text-align: center;
      font-size: 1.2rem;
      color: #535461;
      font-weight: bold;
    }
    .main_services h3 {
      margin-top: 1.5em;
      margin-bottom: 2em;
      text-align: center;
      font-weight: bolder;
      font-size: 1.34rem;
      color: #535461;
    }
    .green_div {
      display: flex;
      width: 95%;
      margin: auto;
    }
    green_div img {
      width: 15px;
      position: relative;
      z-index: -1;
    }
    .greendivp {
      display: block;
      font-size: 0.9rem;
      text-align: left;
      margin: auto;
      color:#35375E;
      opacity: 0;
    }
    .ull {
      width: 90%;
      display: block;
      font-size: 0.95rem;
      text-align: left;
      margin: auto;
      margin-top: 1em;
      margin-bottom: 1em;
      font-weight: bold;
      color:#35375E;
      opacity: 0;
    }
    .lli {
      color:#35375E;
      margin-top: 0.3em;
    }
    .sales_div {
      display: flex;
      width: 95%;
      margin: auto;
    }
    .salesdivp {
      width: 95%;
      display: block;
      font-size: 0.9rem;
      text-align: left;
      margin: auto;
      margin-top: 2em;
      color:#35375E;
      opacity: 0;
    }
    .sales_div img {
      margin-top: -3em;
      margin-left: -1em;
      position: relative;
    }
    .adsales_banner {
      margin-top: 3em;
    }
    .adsales_bannerh4 {
      font-size: 1.1rem;
      text-align: center;
      color: #535461;
      opacity: 0;
    }
    .one {
      display: block;
      margin-top: 3em;
      color:#35375E;
      opacity: 0;
      transform: translateY(-15px);
    }
    .one_div {
      width: 80%;
      display: grid;
      margin: auto;
      margin-top: 1em;
    }
    .one_icon {
      position: relative;
      float: left;
      margin-left: 1em;
      position: relative;
      margin-top: -1.52em;
      height: 11vh;
    }
    .one_text {
      width: 35%;
      position: absolute;
      background: #fff;
      font-size: 1rem;
      z-index: 10;
      text-align: left;
      padding-top:.31em;
      height: 3vh;
      margin-top: 1em;
    }
    .one_text span {
      font-size: .71rem;
    }
    .one_mini_text {
      text-align: left;
      margin: auto;
      margin-top: 1em;
      color:#35375E;
      width: 95%;
    }
    .one_mini_text p {
      font-size: 0.9rem;
      opacity: 0;
      transform: translateY(0px);
    }
    .polygon {
      position: absolute;
      z-index: -1;
      margin-left: 1em;
    }
    .two {
      display: block;
      margin-top: 2em;
      color:#35375E;
      opacity: 0;
      transform: translateX(0px);
    }
    .two_div {
      width: 80%;
      display: grid;
      margin: auto;
    }
    .two_icon {
      position: relative;
      float: right;
      margin-left: 1em;
      position: relative;
      margin-top: -1.52em;
      height: 11vh;
    }
    .two_text {
      width: 100%;
      position: absolute;
      background: #fff;
      font-size: 1rem;
      z-index: 10;
      text-align: right;
      padding-top: .31em;
      height: 3vh;

      margin-top:1em;
    }
    .two_text span {
      font-size: .71rem;
    }
    .two_mini_text {
      text-align: center;
      margin: auto;
      margin-top: 1em;
      color:#35375E;
      width: 95%;
    }
    .two_mini_text p {
      font-size: 0.9rem;
      text-align: left;
      opacity: 0;
      transform: translateY(0px);
    }
    .red_div {
      text-align: right;
      width: 95%;
    }
    .rectangle {
      text-align: center;
      position:absolute;

    }
    .red {
      text-align: left !important;
      margin-top: 3em;
    }
    .polygon {
      position: absolute;
      z-index: -1;
      margin-left: 1em;
    }
    .ellipse {
      float: left;
      margin-left: 2em;
      margin-top: 3em;
    }
    .three {
      display: block;
      margin-top: 3em;
      color:#35375E;
      opacity: 0;
      transform: translateX(0px);
    }
    .three_div {
      width: 80%;
      display: grid;
      margin: auto;
      margin-top: 2em;
    }
    .three_icon {
      position: relative;
      float: left;
      margin-left: 1em;
      position: relative;
      margin-top: -1.52em;
      height: 11vh;
    }
    .three_text {
      width: 50%;
      position: absolute;
      background: #fff;
      font-size: 1rem;
      z-index: 10;
      text-align: left;
      padding-top: 0.31em;
      height: 3vh;

      margin-top: 0.57em;
    }
    .three_mini_text {
      text-align: left;
      margin: auto;
      margin-top: 1em;
      display: flex;
      justify-content: flex-end;
      width: 90%;
      color:#35375E;
    }
    .three_text span {
      font-size: .71rem;
    }
    .three_mini_text p {
      font-size: 0.9rem;
         opacity: 0;
      transform: translateY(0px);
    }
    .poygon {
      width: 2%;
      position: absolute;
      z-index: -5;
    }
    .tw_divmax {
      display: none;
    }
    .spann {
      font-weight: bold;
    }
  }

  @media (min-width: 960px) and (max-width: 1500px) {
    .main_services h4 {
      font-size: 1.9rem;
      font-weight: bold;
      margin-bottom: 1.5em;
      color: #535461;
      margin-top: 4em;
    }
    .main_services h3 {
      font-size: 2rem;
      font-weight: bolder;
      margin-bottom: 2em;
      color: #535461;
    }
    .green_div {
      width: 80%;
      color:#35375E;

      margin: auto;

      font-size: 1.1rem;

      text-align: left;
    }
    .greendivp {
      opacity: 0;
      width: 100%;
      color:#35375E;

      margin: auto;

      font-size: 1.1rem;

      text-align: left;
    }

    .ull {
      text-align: left;
      width: 80%;
      font-size: 1.2rem;
      color:#35375E;
      opacity: 0;
      margin: auto;
    }
    .lli {
      width: 80%;
      text-align: left;
    }
    .sales_div {
      width: 80%;
      color:#35375E;

      margin: auto;

      font-size: 1.1rem;
      margin-top: 2em;
      text-align: left;
    }
    .salesdivp {
      opacity: 0;
      width: 100%;
      color:#35375E;

      margin: auto;

      font-size: 1.1rem;

      text-align: left;
    }
    .green {
      opacity: 0;
      transform: translateY(-18px);
    }
    .adsales_bannerh4 {
      margin-top: 2em;
      margin-bottom: 2em;
      color: #535461;
      opacity: 0;
      font-size: 1.5rem;
    }
    .one {
      display: block;
      margin-top: 3em;
      color:#35375E;
      opacity: 0;
      transform: translateY(-15px);
    }
    .one_div {
      width: 80%;
      display: flex;
      margin: auto;
      margin-top: 3em;
    }
    .on_div {
      width: 30%;
    }
    .one_icon {
      position: relative;
      float: left;
      margin-left: 1em;
      position: relative;
      margin-top: -1.52em;
      height: 20vh;
    }
    .one_text {
      width: 100%;
      position: absolute;
      background: #fff;
      font-size: 0.8rem;
      z-index: 10;
      text-align: left;
      padding-top: 1em;
      height: 5.5vh;
      margin-top: 1em;
    }
    .one_mini_text {
      text-align: left;
      margin: auto;
      margin-top: auto;
      margin-left: auto;
      margin-top: 2em;
      color:#35375E;
      width: 55%;
      position: relative;
      z-index: 10;
      margin-left: 3em;
    }
    .one_mini_textp {
      font-size: 1.1rem;
      opacity: 0;
      transform: translateY(0px);
    }
    .polygon {
      position: absolute;
      z-index: -1;
      margin-left: 1em;
    }
    .two {
      display: block;
      margin-top: 6em;
      color:#35375E;
      opacity: 0;
      transform: translateX(0px);
    }
    .tw_div {
      display: none;
    }
    .two_div {
      width: 80%;
      display: flex;
      margin: auto;
      margin-top: 7em;
    }
    .two_icon {
      position: relative;
      float: right;
      margin-left: 1em;
      position: relative;
      margin-top: -1.52em;
      height: 15vh;
    }
    .two_text {
      width: 65%;
      position: absolute;
      background: #fff;
      font-size: 0.8rem;
      z-index: 10;
      text-align: right;
      padding-top: 1em;
      height: 5.5vh;
      margin-top: 1em;
    }
    .two_mini_text {
      margin: auto;
      margin-top: 2em;
      color:#35375E;
      width: 55%;
      text-align: center;
      position: relative;
      z-index: 10;
    }
    .two_mini_textp {
      font-size: 1.1rem;
      text-align: left;
      opacity: 0;
      transform: translateX(0px);
    }
    .red_div {
      text-align: right;
      width: 95%;
    }
    .rectangle {
      text-align: center;
      position:absolute;

    }
    .red {
      text-align: left !important;
      margin-top: 3em;
      opacity: 0;
      transform: translateY(-15px);
    }
    .polygon {
      position: absolute;
      z-index: -1;
      margin-left: 1em;
    }
    .ellipse {
      float: left;
      margin-left: 2em;
      margin-top: 3em;
      opacity: 0;
      transform: translateY(-15px);
    }
    .three {
      display: block;
      margin-top: 3em;
      color:#35375E;
      opacity: 0;
      transform: translateX(0px);
    }
    .three_div {
      width: 80%;
      display: flex;
      margin: auto;
      margin-top: 7em;
    }
    .thr_div {
      width: 30%;
    }
    .three_icon {
      position: relative;
      float: left;
      margin-left: 1em;
      position: relative;
      margin-top: -1.52em;
      height: 20vh;
    }
    .three_text {
      width: 80%;
      position: absolute;
      background: #fff;
      font-size: 0.8rem;
      z-index: 10;
      text-align: left;
      padding-top: 1em;
      height: 5.5vh;
      margin-top:1em;
    }
    .three_mini_text {
      margin: auto;
      margin-top: 2em;
      color:#35375E;
      width: 55%;
      text-align: left;
      position: relative;
      z-index: 10;
    }
    .three_mini_textp {
      font-size: 1.1rem;
      opacity: 0;
      transform: translateX(0px);
    }
    .poygon {
      width: 2%;
      position: absolute;
    }
    .twomax {
      display: block;
      margin-top: 3em;
      color:#35375E;
      opacity: 0;
      transform: translateX(0px);
    }
    .tw_divmax {
      width: 30%;
      float: inline-end;
    }
    .two_divmax {
      width: 80%;
      display: block;
      margin: auto;
      margin-top: 12em;
    }
    .two_iconmax {
      position: relative;
      float: right;
      margin-left: 1em;
      position: relative;
      margin-top: -1.52em;
      height: 20vh;
    }
    .two_textmax {
      width: 100%;
      position: absolute;
      background: #fff;
      font-size: 0.8rem;
      z-index: 10;
      text-align: right;
      padding-top: 1em;
      height: 5.5vh;
      margin-top: 1em;
    }
    .maingreendiv {
      opacity: 0;
      transform: translateY(0px);
    }
    .spann {
      font-weight: bold;
    }
  }

  @media (min-width: 1500px) {
    .main_services h4 {
      font-size: 1.9rem;
      font-weight: bold;
      margin-bottom: 1.5em;
      color: #535461;
      margin-top: 3em;
    }
    .main_services h3 {
      font-size: 2rem;
      font-weight: bolder;
      margin-bottom: 2em;
      color: #535461;
    }
    .green_div {
      width: 80%;
      color:#35375E;

      margin: auto;

      font-size: 1.1rem;

      text-align: left;
    }
    .greendivp {
      opacity: 0;
      width: 80%;
      color:#35375E;

      margin: auto;

      font-size: 1.1rem;

      text-align: left;
    }
    .sales_div {
      width: 80%;
      color:#35375E;

      margin: auto;

      font-size: 1.1rem;
      margin-top: 2em;
      text-align: left;
    }
    .salesdivp {
      opacity: 0;
      width: 80%;
      color:#35375E;

      margin: auto;

      font-size: 1.1rem;

      text-align: left;
    }
    .green {
      opacity: 0;
      transform: translateY(-18px);
    }
    .adsales_bannerh4 {
      margin-top: 2em;
      margin-bottom: 2em;
      color: #535461;
      opacity: 0;
      font-size: 1.5rem;
    }
    .one {
      display: block;
      margin-top: 3em;
      color:#35375E;
      opacity: 0;
      transform: translateY(-15px);
    }
    .one_div {
      width: 70%;
      display: flex;
      margin: auto;
      margin-top: 3em;
    }
    .on_div {
      width: 20%;
      margin: auto;
    }
    .one_icon {
      position: relative;
      float: left;
      margin-left: 1em;
      position: relative;
      margin-top: -1.52em;
      height: 15vh;
    }
    .one_text {
      width: 100%;
      position: absolute;
      background: #fff;
      font-size: 0.8rem;
      z-index: 10;
      text-align: left;
      padding-top: 1em;
      height: 5.5vh;
      margin-top: 1.82em;
    }
    .one_mini_text {
      text-align: left;
      margin: auto;
      margin-top: auto;
      margin-left: auto;
      margin-top: 2em;
      color:#35375E;
      width: 45%;
      position: relative;
      z-index: 10;
      margin-left: 3em;
    }
    .one_mini_textp {
      font-size: 1.1rem;
      opacity: 0;
      transform: translateY(0px);
    }
    .polygon {
      position: absolute;
      z-index: -1;
      margin-left: 1em;
    }
    .two {
      display: block;
      margin-top: 6em;
      color:#35375E;
      opacity: 0;
      transform: translateX(0px);
    }
    .tw_div {
      display: none;
    }
    .two_div {
      width: 70%;
      display: flex;
      margin: auto;
      margin-top: 7em;
    }
    .two_icon {
      position: relative;
      float: right;
      margin-left: 1em;
      position: relative;
      margin-top: -1.52em;
      height: 18vh;
    }
    .two_text {
      width: 65%;
      position: absolute;
      background: #fff;
      font-size: 0.8rem;
      z-index: 10;
      text-align: right;
      padding-top: 0.5em;
      height: 5.5vh;
      margin-top: 0.6em;
    }
    .two_mini_text {
      margin: auto;
      margin-top: auto;
      margin-top: 2em;
      color:#35375E;
      width: 45%;
      text-align: center;
      position: relative;
      z-index: 10;
    }
    .two_mini_textp {
      font-size: 1.1rem;
      text-align: left;
      opacity: 0;
      transform: translateX(0px);
    }
    .red_div {
      text-align: right;
      width: 95%;
    }
    .rectangle {
      text-align: center;
    }
    .red {
      text-align: left !important;
      margin-top: 3em;
      opacity: 0;
      transform: translateY(-15px);
    }
    .polygon {
      position: absolute;
      z-index: -1;
      margin-left: 1em;
    }
    .ellipse {
      float: left;
      margin-left: 2em;
      margin-top: 3em;
      opacity: 0;
      transform: translateY(-15px);
    }
    .three {
      display: block;
      margin-top: 3em;
      color:#35375E;
      opacity: 0;
      transform: translateX(0px);
    }
    .three_div {
      width: 70%;
      display: flex;
      margin: auto;
      margin-top: 7em;
    }
    .thr_div {
      width: 20%;
      margin: auto;
    }
    .three_icon {
      position: relative;
      float: left;
      margin-left: 1em;
      position: relative;
      margin-top: -1.52em;
      height: 15vh;
    }
    .three_text {
      width: 85%;
      position: absolute;
      background: #fff;
      font-size: 0.8rem;
      z-index: 10;
      text-align: left;
      padding-top: 1em;
      height: 5.5vh;
      margin-top: 1.84em;
    }
    .three_mini_text {
      margin: auto;
      margin-top: 2em;
      color:#35375E;
      width: 45%;
      text-align: left;
      position: relative;
      z-index: 10;
    }
    .three_mini_textp {
      font-size: 1.1rem;
      opacity: 0;
      transform: translateX(0px);
    }
    .poygon {
      width: 2%;
      position: absolute;
    }
    .twomax {
      display: block;
      margin-top: 3em;
      color:#35375E;
      opacity: 0;
      transform: translateX(0px);
    }
    .tw_divmax {
      width: 20%;
      float: inline-end;
    }
    .two_divmax {
      width: 80%;
      display: block;
      margin: auto;
      margin-top: 12em;
    }
    .two_iconmax {
      position: relative;
      float: right;
      margin-left: 1em;
      position: relative;
      margin-top: -1.52em;
      height: 18vh;
    }
    .two_textmax {
      width: 100%;

      position: absolute;

      background: #fff;

      font-size: 0.8rem;

      z-index: 10;

      text-align: right;

      padding-top: 1em;

      height: 5vh;

      margin-top: 1.86em;
    }
    .maingreendiv {
      opacity: 0;
      transform: translateY(0px);
    }
    .ull {
      width: 60%;
      margin: auto;
      color:#35375E;
      opacity: 0;
    }
    .lli {
      text-align: left;
    }
    .spann {
      font-weight: bold;
    }
  }
`;

export default function Services() {
  const tl = new TimelineMax();
  const greendiv = React.useRef(null);
  const greendivp = React.useRef(null);
  const green = React.useRef(null);
  const ull = React.useRef(null);
  const salesdiv = React.useRef(null);
  const salesdivp = React.useRef(null);
  const blue = React.useRef(null);
  const one = React.useRef(null);
  const adsalesh4 = React.useRef(null);
  const onetextp = React.useRef(null);
  const polygon = React.useRef(null);
  const ellipse = React.useRef(null);
  const two = React.useRef(null);
  const twotext = React.useRef(null);
  const twomax = React.useRef(null);
  const twotextmax = React.useRef(null);
  const twotextp = React.useRef(null);
  const rectangle = React.useRef(null);
  const red = React.useRef(null);
  const three = React.useRef(null);
  const threetext = React.useRef(null);
  const threetextp = React.useRef(null);
  const poygon = React.useRef(null);
  const maingreendiv = React.useRef(null);

  const one_mini_textp = React.useRef(null);

  React.useEffect(() => {
    tl.to(maingreendiv.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });
    tl.to(greendivp.current, {
      translateY: -25,
      translateY: 25,
      ease: "Bounce.easeOut",
      delay: 0.31,
      opacity: 1,
    });
    tl.to(
      ull.current,

      {
        translateY: -25,
        translateY: 25,
        ease: "Bounce.easeOut",
        duration: 0.41,
        delay: 0.31,
        opacity: 1,
      }
    );
    tl.to(
      salesdivp.current,

      {
        translateY: -25,
        translateY: 25,
        ease: "Bounce.easeOut",
        duration: 0.51,
        delay: 0.31,
        opacity: 1,
      }
    );
    tl.to(
      adsalesh4.current,

      {
        translateY: -25,
        translateY: 25,
        ease: "Bounce.easeOut",
        duration: 0.51,
        delay: 0.51,
        opacity: 1,
      }
    );
    tl.to(one.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
      delay: 0.1,
    });

    tl.to(onetextp.current, 1, {
      translateX: -15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });

    tl.to(two.current, 1, {
      translateX: -15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });
    tl.to(twomax.current, 1, {
      translateX: -15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
      delay: 0.1,
    });
    tl.to(twotextmax.current, 1, {
      translateX: -15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
      delay: 0.1,
    });

    tl.to(twotextp.current, 1, {
      translateX: -15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });

    tl.to(three.current, 1, {
      translateX: 15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });
    tl.to(threetextp.current, 1, {
      translateX: -15,
      ease: "elastic.out(1, 0.3)",
      opacity: 1,
    });
    tl.to(rectangle.current, 1, {
      rotate: 360,
      repeat: -1,
      opacity: 1,
    });
    tl.to(ellipse.current, 1, {
      translateY: 15,
      delay: 0.1,
      yoyo: true,
      repeat: -1,
      opacity: 1,
    });
    tl.to(polygon.current, 1, {
      rotate: 360,
      repeat: -1,
      opacity: 1,
    });
    tl.to(red.current, 1, {
      translateY: 15,
      delay: 0.1,
      yoyo: true,
      repeat: -1,
      opacity: 1,
    });
    tl.to(green.current, 1, {
      translateY: 18,
      yoyo: true,
      repeat: -1,
      opacity: 1,
    });
    tl.to(poygon.current, 1, {
      rotate: 180,
      repeat: -1,
      opacity: 1,
    });
  }, [tl]);
  return (
    <Main>
      <Header />
      <div className="main_services">
        <h4>OUR SERVICES</h4>
        <div className="maingreendiv" ref={maingreendiv}>
          <h3>UNLEASH YOUR ADVERTISING PROGRAM’S POTENTIAL</h3>
          <div className="green_div" ref={greendiv}>
            <p className="greendivp" ref={greendivp}>
              Ad sales are a key component of funding your operations. However,
              you may have underutilized ad space, unsold placements in existing
              publications or undisclosed digital and print sources waiting to
              be monetized. Publishers and associations come to{" "}
              <span className="spann"> CHARGEADS </span>
              because :{" "}
            </p>
            <img
              src={require("../images/green.svg")}
              alt="green"
              className="green"
              ref={green}
            />
          </div>
          <ul className="ull" ref={ull}>
            <li className="lli">
              Their current ad sales agency is underperforming.
            </li>
            <li className="lli">
              Their growing organization needs to replace its in-house program.{" "}
            </li>
          </ul>
        </div>
        <div className="sales_div" ref={salesdiv}>
          <p className="salesdivp" ref={salesdivp}>
            The success of our ad sales respresentation is proven. On a yearly
            average, we help clients acheive $350,000 in new revenue with new
            advertisers.{" "}
          </p>
          <img
            src={require("../images/blue.svg")}
            alt="green"
            className="green"
            ref={blue}
          />
        </div>
      </div>
      <div className="adsales_banner">
        <h4 ref={adsalesh4} className="adsales_bannerh4">
          AD SALES REPRESENTATION
        </h4>
        <div className="one_div">
          <div className="on_div">
            <div className="one" ref={one}>
              <img
                src={require("../images/01.svg")}
                className="one_icon"
                alt="one"
              />
              <div className="one_text">
                <span>Digital Ad Sales Respresentation</span>
              </div>
            </div>
          </div>
          <div className="one_mini_text">
            <img
              src={require("../images/Polygon1001.svg")}
              className="polygon"
              alt="polygon"
              ref={polygon}
            />
            <p className="one_mini_textp" ref={onetextp}>
              Improve existing digital ad sales and launch new revenue
              generating platforms. As part of digital ad sales representation,
              <span className="spann"> CHARGEADS </span> extends your inventory
              to websites, mobile apps, digital print and so on.
            </p>
          </div>
          <div>
            <img
              src={require("../images/ellipse.svg")}
              alt="ellipse"
              className="ellipse"
              ref={ellipse}
            />
          </div>
        </div>

        <div className="two_div">
          <div className="tw_div">
            <div className="two" ref={two}>
              <img
                src={require("../images/02.svg")}
                className="two_icon"
                alt="two"
              />
              <div className="two_text">
                <span>Print Ad Sales Respresentation</span>
              </div>
            </div>
          </div>
          <div className="two_mini_text">
            <img
              src={require("../images/Rectangle.svg")}
              className="rectangle"
              alt="rectangle"
              ref={rectangle}
            />
            <p ref={twotextp} className="two_mini_textp">
              Increase revenue and reach for your adveritising products. With
              print ads representation,{" "}
              <span className="spann">CHARGEADS </span> develops new prospects
              into paying advertisers, grows earnings per ad, and offers end to
              end social management process
            </p>
          </div>
          <div className="tw_divmax">
            <div className="twomax" ref={twomax}>
              <img
                src={require("../images/02.svg")}
                className="two_iconmax"
                alt="two"
              />
              <div className="two_textmax">
                <span>Print Ad Sales Respresentation</span>
              </div>
            </div>
            <div className="red_div">
              <img
                src={require("../images/red.svg")}
                alt="red"
                className="red"
                ref={red}
              />
            </div>
          </div>
        </div>

        <div className="three_div">
          <div className="thr_div">
            <div className="three" ref={three}>
              <img
                src={require("../images/03.svg")}
                className="three_icon"
                alt="three"
              />
              <div className="three_text">
                <span>TV, Exhibit/Conference Sponsorships</span>
              </div>
            </div>
          </div>
          <div className="three_mini_text">
            <p ref={threetextp} className="three_mini_textp">
              Partner with <span className="spann"> CHARGEADS </span> to acquire
              advertisers for every aspect of your event. We develop prospect
              leads, network with potential advertisers at events, and close
              exhibit sales and sponsorships on your behalf. Manage your
              conference while <span className="spann"> CHARGEADS </span> focuses
              on your revenue. 
              <span className="spann"> CHARGEADS </span> leverages our
              cross-media expertise in print ad sales and digital sales to
              support your event. We also have extensive experience in
              association advertising sales and in developing new sources of
              non-dues revenue. One of our priorities is creating a seamless
              experience for your prospects. We learn your business and your ad
              products, and we optimize packages for your target audience. Being
              part of your team empowers us to identify, acquire, and manage
              advertisers. We also look beyond your conference to opportunities
              in your print and digital publications. We leverages relationships
              with conference sponsors and advertisers to grow revenue
              year-round from your magazine, newsletter, website, and other
              non-event channels
            </p>
            <img
              src={require("../images/darkgrey.svg")}
              alt="ellipse"
              className="poygon"
              ref={poygon}
            />
            <div></div>
          </div>
        </div>
      </div>
      <Footer />
    </Main>
  );
}
